<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import ReLogin from '../pages/account/re-login'

export default {
  components: { Vertical, Horizontal, ReLogin },
  data() {
    return {
      user: {},
      main: 'main',
    };
  },
  computed: {
    ...layoutComputed
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  methods: {
    closeModal(component){
      this.$bvModal.hide(component);
      this.$router.go();
    }
  }
};
</script>

<template>
  <div>
    <b-modal
      centered
      hide-footer
      hide-header-close
      no-close-on-backdrop
      ref="newLogin"
      id="loginModal"
      title-class="font-18"
      title="Session expired. Please login"
    >
      <ReLogin
        :email="user.user.de_email"
        @success="closeModal('loginModal')"
      />
    </b-modal>
    <vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>